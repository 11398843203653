<template>
    <div class="clothes-list-box">
        <!-- 轮播图 -->
        <banner-carousel></banner-carousel>
        <!-- 过滤条件 -->
        <filter-share
            :filter-hotwords="hotwords"
            :filter-style="style"
            :filter-price="price"
            :remote-methods="getCameraList">
        </filter-share>
        <!-- 列表 -->
        <div class="clothes-list">
            <div class="container">
                <el-row :gutter="20">
                    <el-col 
                        :span="6"
                        v-for="item in goodsList"
                        :key="item.id">
                        <clothes-list-item
                            :item-data="item">
                        </clothes-list-item>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 分页器 -->
        <div class="page-box container">
            <el-pagination
                :page-count="parseInt(page.page_count)"
                :current-page="parseInt(page.current_page)"
                layout="prev, pager, next, jumper"
                @current-change="handlePage"
                background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: mapState({
        goodsList: state => state.goodsList.list,
        page: state => state.goodsList.page,
        hotwords: state => state.goodsList.hotwords,
        style: state => state.goodsList.style,
        price: state => state.goodsList.price
    }),
    components:{
        bannerCarousel: () => import('@/components/bannerCarousel'),
        clothesListItem: () => import("@/components/clothesListItem"),
        filterShare: () => import("@/components/filterShare"),
    },
    methods:{
        ...mapMutations([
            "clearList", // 清除列表数据
        ]),
        ...mapActions([
            "getCameraList",
            "getClothesBanner",
            "getLeaseCondition"
        ]),
        handlePage(arg){
            let search = sessionStorage.getItem("search") || "",
            style = sessionStorage.getItem("sift_style") || "0",
            clazz = sessionStorage.getItem("sift_type") || "0",
            price = sessionStorage.getItem("sift_price") || "0",
            area = sessionStorage.getItem("sift_area") || "";
            this.getCameraList({
                search,
                area, 
                style, 
                clazz, 
                price,
                page: arg
            });
        },
    },
    beforeRouteEnter (to, from, next) {
        let search = sessionStorage.getItem("search") || "",
        style = sessionStorage.getItem("sift_style") || "0",
        clazz = sessionStorage.getItem("sift_type") || "0",
        price = sessionStorage.getItem("sift_price") || "0",
        area = sessionStorage.getItem("sift_area") || "";
        next(vm => {
            vm.getCameraList({
                search,
                area, 
                style, 
                clazz, 
                price
            });
            vm.getClothesBanner();
            vm.getLeaseCondition();
        });
    },
    beforeRouteLeave (to, from, next) {
        sessionStorage.removeItem("search");
        sessionStorage.removeItem("sift_area");
        sessionStorage.removeItem("sift_style");
        sessionStorage.removeItem("sift_type");
        sessionStorage.removeItem("sift_price");
        this.clearList();
        next();
    },
}
</script>

<style scoped>
.clothes-list-box{
    background-color: #fafafa;
    /* background-image: url('../assets/images/bg.jpg'); */
    background-size: cover;
}
/* 商品 */
.clothes-list{
    padding: 30px 0;
}
.clothes-list .el-col{
    margin-bottom: 15px;
}
</style>